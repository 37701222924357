import Vue from 'vue'
import axios from 'axios'
import router from './router.js'

Vue.prototype.$http = axios
Vue.prototype.$http.defaults.withCredentials = true;

Vue.prototype.$http.interceptors.request.use(
    (config) => {
        const authToken = localStorage.getItem("authToken");

        if (authToken) {
            config.headers["Authorization"] = `Bearer ${authToken}`;
        }
        
        let currency = localStorage.getItem('currency');
        if (!currency) {
            currency = 'EUR'
        }
        
        if (config.method === 'get') {
            config.params = {
                ...config.params,
                currency
            }
        } else if(['post', 'put', 'patch', 'POST', 'PUT', 'PATCH'].includes(config.method)) {
            if (config.data instanceof FormData) {
                config.data.append('currency', currency)
            } else {
                config.data = {
                    ...config.data,
                    currency,
                }
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Vue.prototype.$http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            localStorage.setItem("isAuthenticated", false.toString());
            // router.push({ name: "Home" }).catch(() => {})
        }
        if (error.response.status === 403) {
            localStorage.setItem('emailVerified', false.toString());
            router.push({ name: "EmailVerificationNotice" }).catch(() => {});
        }
        return Promise.reject(error);
    }
);