<template>
  <div class="modal register-modal">
    <div class="overlay"  @click="$parent.closePersonalInfoModal"></div>
    <div class="wrapper">
      <img class="close" @click="$parent.closePersonalInfoModal" src="./../img/close.svg"/>
      <div class="container">
        <div class="form">
          <div class="form-wrapper">
            <div class="form-fields">
              <div class="form-fields-wrapper">
                <div class="title">Checkout</div>
                <div class="label-list">
                  <label>
                    <div class="desc">*First name</div>
                    <input type="text" placeholder="First name" v-model="name"/>
                  </label>
                  <label>
                    <div class="desc">*Last Name</div>
                    <input type="text" placeholder="Last Name" v-model="surname"/>
                  </label>
                  <label v-if="!$parent.fiatToCrypto" class="full">
                  <div class="desc">*Birthdate</div>
                  <input type="date" placeholder="Last Name" v-model="birthdate"/>
                  </label>
                  <label class="full">
                    <div class="desc">*Email</div>
                    <input type="email" placeholder="Email" v-model="email"/>
                  </label>
                  <label class="full">
                    <div class="desc">*Phone</div>
                    <input type="text" placeholder="Phone" v-model="phone"/>
                  </label>
                  <label>
                    <div class="desc">*Address</div>
                    <input type="text" placeholder="Address" v-model="address"/>
                  </label>
                  <label>
                    <div class="desc">*City</div>
                    <input type="text" placeholder="City" v-model="city"/>
                  </label>
                  <label>
                    <div class="desc">*Post code</div>
                    <input type="email" placeholder="Post code" v-model="postCode"/>
                  </label>
                  <label>
                    <div class="desc">*Country</div>
                    <select v-model="country">
                      <option disabled selected value="">Country</option>
                      <option v-for="(item, i) in $parent.countryOptions" :value="item" :key="i">{{ item.title }}</option>
                    </select>
                  </label>
                  <label class="full">
                    <div class="desc">*Wallet</div>
                    <input type="name" placeholder="Wallet" v-model="wallet"/>
                  </label>
                  <div class="link-container">
                    <div class="chekbox-container">
                      <label class="chekbox-label full">
                        <div class="chekbox-label-wrapper">
                          <input type="checkbox" name="terms" v-model="terms"/>
                          <div class="checkbox"></div>
                          <span class="desc">I agree with </span>
                          <a @click="$parent.goToPrivacy()" class="desc link"> {{ $parent.textPageList.filter(item => item.is_privacy)[0].title }}</a>
                          <span class="desc"> and </span>
                          <a @click="$parent.goToTerms()" class="desc link"> {{ $parent.textPageList.filter(item => item.is_terms)[0].title }}</a>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <button :class="['button color', {'disabled': !requiredRegisterFieldsAreFilled}]" @click="submit">
                  <span>Submit</span>
                </button>
                <div v-if="$parent.error" class="desc red">{{$parent.error}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PersonalInfoModal',
  props: [],
  components: {
  },
  data: function() {
    return {
      email: '',
      name: '',
      surname: '',
      birthdate: null,
      phone: '',
      country: '',
      countryId: '',
      city: '',
      address: '',
      postCode: '',
      terms: false,
      phoneFull: '',
      wallet: ''
    }
  },
  mounted() {
    
  },
  computed: {
    requiredRegisterFieldsAreFilled() {
      let fieldsAreFilled;

      if(
        this.name && this.surname  && this.phone && 
        this.email && this.terms && this.city && this.country && 
        this.postCode && this.address  && (this.$parent.fiatToCrypto || this.birthdate)
      ) {
        fieldsAreFilled = true;
      } else {
        fieldsAreFilled = false;
      }
      return fieldsAreFilled;
    }
  },
  methods: {
    phoneDataUpdate(e) {
      this.phoneFull = e.formatInternational;
    },
    submit() {
      this.$emit('setPersonalInfo', this.email, this.name, this.surname, this.phone, this.postCode, this.country.id, this.city, this.address, this.birthdate, this.wallet)
      this.$emit('orderSubmit');
    },
  }
}
</script>