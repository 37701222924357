<template>
  <div class="exchange-card card">
    <div class="card-top">
      <div 
        :class="['desc link hover-link', {'active': cryptoFormState == 'buy'}]" 
        @click="cryptoFormState = 'buy'"
      >
        Buy
      </div>
      <div 
        :class="['desc link hover-link', {'active': cryptoFormState == 'sell'}]" 
        @click="cryptoFormState = 'sell'"
      >
        Sell
      </div>
    </div>
    <div class="card-middle">
      <div class="flex">
        <div class="select-container" v-if="cryptoFormState == 'sell'">
          <div class="select-wrapper" v-if="chosenCrypto">
            <input class="input" v-model="cryptoAmount" @input="changeGiveAmount($event.target.value);"/>
            <label>
              <img v-if="chosenCrypto.image" class="img" :src="chosenCrypto.image" />
              <select 
                class="currency-select" 
                v-if="exchangeList" 
                v-model="chosenCrypto"
                @change="changeTakeAmount(giveAmount)"
              >
                <option v-for="item in exchangeList" :key="item.id" :value="item">{{ item.title }}</option>
              </select>
            </label>
          </div>
        </div>
        <div class="select-container" v-if="cryptoFormState == 'buy'">
          <div class="select-wrapper" v-if="chosenFiat">
            <input class="input" v-model="fiatAmount" @input="changeTakeAmount($event.target.value);"/>
            <label>
              <img v-if="chosenFiat.image" class="img" :src="chosenFiat.image" />
              <select 
                class="currency-select" 
                v-if="fiatList" 
                v-model="chosenFiat"
                @change="changeFiat($event)"
              >
                <option v-for="item in fiatList" :key="item.id" :value="item">{{ item.title }}</option>
              </select>
            </label>
          </div>
        </div>
        <div class="swap">
          <div class="desc desc-info">Minimum deposit amount - {{ chonsenCurrencyLimit }} {{ chosenCurrency }}</div>
          <img src="./../img/swap.svg" class="img exchange-img"/>
        </div>
        <div class="select-container" v-if="cryptoFormState == 'sell'">
          <div class="select-wrapper" v-if="chosenFiat">
            <input class="input" disabled v-model="fiatAmount" @input="changeTakeAmount($event.target.value);"/>
            <label>
              <img v-if="chosenFiat.image" class="img" :src="chosenFiat.image" />
              <select 
                class="currency-select" 
                v-if="fiatList" 
                v-model="chosenFiat"
              >
                <option v-for="item in fiatList" :key="item.id" :value="item">{{ item.title }}</option>
              </select>
            </label>
          </div>
        </div>
        <div class="select-container" v-if="cryptoFormState == 'buy'">
          <div class="select-wrapper" v-if="chosenCrypto">
            <input class="input" disabled v-model="cryptoAmount" />
            <label>
              <img v-if="chosenCrypto.image" class="img" :src="chosenCrypto.image" />
              <select 
                class="currency-select" 
                v-if="exchangeList" 
                v-model="chosenCrypto"
                @change="changeTakeAmount(giveAmount)"
              >
                <option v-for="item in exchangeList" :key="item.id" :value="item">{{ item.title }}</option>
              </select>
            </label>
          </div>
        </div>
        <div class="exchange-card-total" v-if="cryptoFormState == 'sell'">
          <div class="desc">You get {{ fiatAmount }} {{ chosenFiat.title }} for {{ cryptoAmount }} {{ chosenCrypto.title }}</div>
        </div>
        <div class="exchange-card-total" v-if="cryptoFormState == 'buy'">
          <div class="desc">You get {{ cryptoAmount }} {{ chosenCrypto.title }} for {{ fiatAmount }} {{ chosenFiat.title }}</div>
        </div>
        <button @click="submit" :class="['button color', {'disabled': !fiatList['EUR'] || !exchangeList.length}]">
          <span>Continue</span>
        </button>
      </div>
    </div>
  </div>
</template>
  
  <script>
  
  export default {
    props: ['type'],
    data() {
      return {
        fiatAmount: '300',
        cryptoAmount: '0,00413',
        cryptoFormState: 'buy',
        exchangeList: [],
        fiatList: [],
        fees: [],
        giveAmount: 300,
        takeAmount: 0,
        chosenFiat: {},
        chosenCrypto: {},
        chosenCurrency: '',
        chonsenCurrencyLimit: '',
        currenciesData: {}
      };
    },
    mounted() {
      this.getExchangeList();
      if (this.type) {
        this.cryptoFormState = this.type;
      }
    },
    methods: {
      getExchangeList() {
        this.$http.get(process.env.VUE_APP_API + 'exchange-top')
        .then((res) => {
          this.exchangeList = res.data.payload;
          this.chosenCrypto = res.data.payload[0]

          this.$http.get(process.env.VUE_APP_API + 'currencies-data')
          .then((res) => {
            this.fiatList = res.data.currencies;
            this.chosenFiat = res.data.currencies['EUR']
            this.fees = res.data.fees;
            this.currenciesData = res.data;
            this.chosenCurrency = res.data.currencies['EUR']['title'];
            this.chonsenCurrencyLimit = res.data.currencies['EUR']['min_deposit_amount'];
            this.changeTakeAmount(this.giveAmount);
          })
          .catch(() => {
          })
        })
        .catch(() => {
        })
        
        
      },
      changeGiveAmount(newValue) {
        this.fiatAmount = this.chosenCrypto.price * newValue;
        this.fiatAmount = this.fiatAmount.toFixed(5);
        console.log('Change give amount:' + newValue)
      },
      changeTakeAmount(newValue) {
        if (this.chosenCrypto.crypto_currency == 'btc') {
          this.cryptoAmount = (newValue - (newValue * this.currenciesData.fees.platform_fee) - this.currenciesData.fees.processing_fee) / this.chosenCrypto.price - this.currenciesData.fees.network_fee[this.chosenCrypto.crypto_currency]
        } else {
          this.cryptoAmount = (newValue / this.chosenCrypto.price) - (newValue * (this.currenciesData.fees.processing_fee / 100)) - (newValue * (this.currenciesData.fees.platform_fee) / 100) - this.currenciesData.fees.network_fee[this.chosenCrypto.crypto_currency]
        }
        this.cryptoAmount = this.cryptoAmount.toFixed(5);
      },
      changeFiat() {
          this.chosenCurrency = this.chosenFiat.title;
          this.chonsenCurrencyLimit = this.chosenFiat.min_deposit_amount;
      },
      submit() {
        let amounts = {
          'fiat': this.fiatAmount,
          'crypto': this.cryptoAmount,
          'fiatCurr': this.chosenFiat,
          'cryptoCurr': this.chosenCrypto,
          'type': this.cryptoFormState,

        }
        this.$emit('setAppAmounts',  amounts)
      }
    },
  };
  </script>
  